.screen-tow-container10 {
  width: 100%;
  height: 700px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  padding-bottom: 5%;
  justify-content: center;
  background-color: #f4f1ec;
}
.screen-tow-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 5%;
  max-width: 1450px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.screen-tow-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: stretch;
  padding-right: 0px;
  flex-direction: column;
  justify-content: space-between;
}
.screen-tow-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-end;
}
.screen-tow-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
}
.screen-tow-container15 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  min-width: 370px;
  align-items: flex-end;
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.screen-tow-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: 0px;
  justify-content: flex-end;
}
.screen-tow-container17 {
  width: 450px;
  height: 350px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-inputradius);
  background-size: cover;
  justify-content: center;
  background-image: url("https://play.teleporthq.io/static/svg/default-img.svg");
  background-position: center;
}
.screen-tow-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-inputradius);
}
.screen-tow-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.screen-tow-container19 {
  flex: 0 0 auto;
  width: 450px;
  height: 120px;
  display: flex;
  max-width: 100%;
  align-self: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: 16px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #050a2f;
}
.screen-tow-text4 {
  width: 400px;
  display: inline-block;
  font-size: 42px;
  max-width: auto;
  font-style: normal;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 20px;
}
.screen-tow-text5 {
  width: 430px;
  display: inline-block;
  font-size: 18px;
  max-width: 430px;
  text-align: justify;
  line-height: 1.5;
}
.screen-tow-text8 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
  text-align: center;
  line-height: 1.5;
}
.screen-towroot-class-name {
  height: 700px;
}
@media (max-width: 1081px) {
  .screen-tow-container10 {
    height: auto;
  }
  .screen-tow-container11 {
    gap: var(--dl-space-space-twounits);
    flex-wrap: wrap;
  }

  .screen-tow-container12 {
    width: 100%;
    padding-right: 0px;
  }
  .screen-tow-container13 {
    max-width: 100%;
  }
  .screen-tow-container15 {
    width: 100%;
    min-width: auto;
  }
  .screen-tow-container17 {
    width: 100%;
    height: auto;
  }
  .screen-tow-container19 {
    width: 100%;
    height: auto;
  }
  .screen-tow-text5 {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 479px) {
  .screen-tow-container10 {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .screen-tow-container11 {
    padding: 0;
  }
  .screen-tow-container15 {
    padding: 0;
  }
}
